import React from 'react'
import { Link } from 'gatsby'
import { css, injectGlobal } from 'emotion'
import Layout from '../components/layout'
import { Container } from '../components/markupHelpers'
import Button from '../components/Button'

injectGlobal`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html, body {
    font-family:
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      "Roboto",
      "Roboto Light",
      "Oxygen",
      "Ubuntu",
      "Cantarell",
      "Fira Sans",
      "Droid Sans",
      "Helvetica Neue",
      sans-serif,
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol";
    font-weight: 400;
    font-size: 16px;
    line-height: 1.25;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }
  p {
    margin: 0 0 20px 0;
    &:last-child {
      margin: 0
    }
  }
  input, textarea, button {
    /* -webkit-appearance: none; */
  }
`

const IndexPage = () => (
  <Layout>
    <Container maxWidth={750}>
      <div
        className={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `}
      >
        <Link to="/reviews">
          <Button blue>
            New Review
          </Button>
        </Link>
        <Link to="/tags">
          <Button blue>
            Tag Courses
          </Button>
        </Link>
      </div>
    </Container>
  </Layout>
)

export default IndexPage
